import { useStaticQuery, graphql } from "gatsby";
import Link from "../components/link";
import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import TopRightFlateBottom from "../components/top-right-flat-bottom";
import TopLeftSection from "../components/top-left-section";
import { Helmet } from "react-helmet";
import PhotoSide from "../components/photo-side";
import SvgFanHp from "../components/svg/fan-svg-hp";
import SvgFanFi from "../components/svg/fan-svg-fi";
import { StaticImage } from "gatsby-plugin-image";
import BottomBanner from "../components/bottom-banner";
import gsap from "gsap";

const FurtherInfoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "faq-1" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      vehicleImg: wpMediaItem(title: { eq: "faq-2" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      promotingImg: wpMediaItem(title: { eq: "faq-1" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      g1Img: wpMediaItem(title: { eq: "faq-3" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const vehicleImg = data.vehicleImg?.localFile.childImageSharp.gatsbyImageData;
  const promotingImg =
    data.promotingImg?.localFile.childImageSharp.gatsbyImageData;
  const g1Img = data.g1Img?.localFile.childImageSharp.gatsbyImageData;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Further Information",
        item: {
          url: `${siteUrl}/further-information`,
          id: `${siteUrl}/further-information`,
        },
      },
    ],
  };

  useEffect(() => {
    gsap.to(".heading-fade", {
      opacity: 1,
      duration: 1,
      delay: 0.5,
      transform: "scale(1)",
    });

    gsap.to(".text-fade", {
      opacity: 1,
      duration: 1,
      delay: 1.1,
      transform: "scale(1)",
    });
    gsap.to(".image-fade", {
      opacity: 1,
      duration: 1,
      delay: 1.4,
      transform: "scale(1)",
    });
  }, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Further Information | Eastbourne Carnival"
        description="The theme this year is ‘Sussex By The Sea’. Get creative and celebrate what you love about our great County."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/further-information`,
          title: "Further Information | Eastbourne Carnival",
          description:
            "The theme this year is ‘Sussex By The Sea’. Get creative and celebrate what you love about our great County.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <section
        style={{ overflow: "hidden" }}
        className="position-relative pb-md-9"
      >
        <SvgFanHp
          style={{ zIndex: -1, opacity: 0.1, height: "130%" }}
          className="position-absolute w-auto d-none d-md-block  top-0 start-50 translate-middle-x "
        />

        <Container>
          <Row>
            <Col>
              <h1
                style={{ opacity: 0, transform: "scale(0.95)" }}
                className="text-center heading-fade display-4 pb-lg-6 pt-4 pt-lg-0  carnival-normal text-secondary"
              >
                Further Information About <br /> Eastbourne Carnival
              </h1>
            </Col>
          </Row>{" "}
          <PhotoSide
            animId="image-fade"
            image={promotingImg}
            imageAlt={data.promotingImg?.altText}
            order="last"
            heading={
              <h2
                style={{ opacity: 0, transform: "scale(0.95)" }}
                className=" text-fade fs-3 pb-4 pb-0 mb-0 shortstack text-secondary d-inline-block"
              >
                What is the theme for the 2024 Eastbourne Carnival?
              </h2>
            }
            text={
              <span
                style={{ opacity: 0, transform: "scale(0.95)" }}
                className="text-fade"
              >
                <p>
                  The theme for this year is ‘Into The Wild. We want the
                  community to get excited and creative about the beauty of the
                  natural environment.
                </p>

                <p>
                  You could be creating something which is relevant to the UK,
                  Sussex or, something from lands further away e.g. tropical
                  rainforests, savannahs – the choice is yours!
                </p>
                <p>
                  We welcome musicians, singers, drummers, bands, sound systems,
                  dancers and those that focus on their costume or floats.
                </p>
                <p>
                  We want this Carnival to be full of colour and performance!
                </p>
              </span>
            }
          />
        </Container>
      </section>
      <section>
        {" "}
        <TopRightFlateBottom
          content={
            <Row>
              <Col className="text-center text-white pt-5 pb-10 py-md-0">
                <h2 className="display-4 carnival-normal mt-md-8   pt-md-10 pb-md-4">
                  Where does Carnival start and what is the route?
                </h2>
                <p>
                  Entrants will be given specific times to line up approximately
                  2 weeks before the Carnival.
                </p>
                <p>
                  The Parade officially begins at 2pm departing from Fishermen’s
                  Green and will end at the Wish Tower Slopes, Western Lawns.
                </p>
              </Col>
            </Row>
          }
          mainBg="bg-secondary"
          secondBg="bg-primary"
          position="top--20"
        />
        <div style={{ height: "35rem" }} className="position-relative">
          <iframe
            className="position-absolute top--20"
            style={{ height: "45rem" }}
            src="https://www.google.com/maps/d/embed?mid=1D5LIOcDj45l3Cjmcgg9110TJ2toK2ZUg&hl=en&ehbc=2E312F"
            width="100%"
          />
        </div>
      </section>
      <section>
        {" "}
        <Container className="px-0 mx-0" fluid>
          {" "}
          <Row className="h-100">
            <Col style={{ minHeight: "100%" }} className="px-0  mx-0" lg={6}>
              <div
                style={{ minHeight: "100%" }}
                className="px-xl-8 px-5 px-lg-7 bg-secondary text-center position-relative pb-md-5 pb-6 pt-md-8 pt-6 text-primary"
              >
                <h3 className="text-center pb-4 shortstack">
                  Performances at Eastbourne Carnival
                </h3>
                <p className="text-start ">
                  Entrants have the opportunity to do a performance facing
                  towards the Bandstand VIP Area. The performance should not
                  exceed 5 minutes where possible - the performance will be
                  judged by the Bandstand audience, together with the ‘People’s
                  Choice’ vote which takes place after Carnival.
                </p>
                <p className="text-start pb-4">
                  You can contact us by email if you have any queries about your
                  entry.
                </p>
                <Button
                  variant="lightBlue"
                  className="fs-5 mx-5 mx-md-0 d-none mb-6 d-lg-inline-block py-3 mx-auto px-5 position-absolute start-50 translate-middle-x  text-white jost-normal bottom-0"
                  as={Link}
                  to="/contact-us"
                >
                  GET IN TOUCH
                </Button>
                <Button
                  variant="lightBlue"
                  className="fs-5 py-3 mx-5 mx-md-0 w-100 w-md-auto d-lg-none mx-auto px-5 text-white jost-normal "
                  as={Link}
                  to="/contact-us"
                >
                  GET IN TOUCH
                </Button>
              </div>
            </Col>
            <Col className="px-0 mx-0" lg={6}>
              <div className="px-xl-8 px-lg-7 px-5 bg-primary text-center position-relative pb-md-5 pb-6 pt-md-8 pt-6 text-secondary">
                <h3 className="text-center pb-4 shortstack">
                  I want help with my entry for Eastbourne Carnival
                </h3>
                <p className="text-start ">
                  We will be working with creatives in our community including
                  Cotton Candy and Cherry Dance to help entrants with costume
                  making and float making and dance performances.
                </p>

                <p className="text-start">
                  Keep an eye on the News Section of our website or on our
                  social media for updates on these or contact us by email for
                  more information.
                </p>
                <p className="text-start pb-lg-10">
                  {" "}
                  There is also a{" "}
                  <a
                    className="text-secondary text-hover-white"
                    href="https://www.facebook.com/groups/eastbournecarnivalentrants"
                  >
                    Facebook Group
                  </a>{" "}
                  for Entrants.
                </p>
                <Button
                  variant="secondary"
                  className="fs-5 py-3 mx-5 mx-md-0 d-none mb-6 d-lg-inline-block mx-auto px-5 text-white jost-normal position-absolute start-50 translate-middle-x bottom-0"
                  as={Link}
                  to="/contact-us"
                >
                  GET IN TOUCH
                </Button>
                <Button
                  variant="secondary"
                  className="fs-5 py-3 mx-5 mt-4 mx-md-0 w-100 w-md-auto d-lg-none mx-auto px-5 text-white jost-normal "
                  as={Link}
                  to="/contact-us"
                >
                  GET IN TOUCH
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-md-10 pt-5 pb-md-8">
        <Container>
          {" "}
          <PhotoSide
            image={vehicleImg}
            imageAlt={data.vehicleImg?.altText}
            heading={
              <h2 className=" fs-3 pb-4 pb-0 mb-0 shortstack text-secondary d-inline-block">
                I want to use a vehicle as part of my entry – what insurances
                will I need?
              </h2>
            }
            text={
              <span>
                <p>
                  All entrants with an accompanying vehicle need insurance to
                  cover their band.
                </p>
                <p>
                  Specific insurance needs to be arranged for your vehicle to
                  cover how you intend to use it during the Carnival.
                </p>
                <p>
                  Additionally, wheel Marshals need to be in situ to prevent any
                  accidents or injuries to participants on foot.
                </p>
                <p>
                  We ask that you have a minimum of two marshals wearing high
                  viz bibs walking either side of the front wheels of each
                  vehicle.
                </p>
                <p>
                  You can contact us by email if you have any queries about
                  Insurance and Health & Safety for your entry.
                </p>
              </span>
            }
          />
        </Container>
      </section>
      <section>
        <TopLeftSection
          content={
            <Row>
              <Col className="text-center py-5 py-md-0 text-white">
                <h2 className="display-4 carnival-normal mt-lg-5 pt-md-10 pb-4">
                  Fundraising at
                  <span className="position-relative">
                    {" "}
                    Carnival
                    <SvgFanFi
                      style={{ width: "150px", height: "150px" }}
                      className="position-absolute d-none d-lg-block bottom-40 start-40 translate-middle-x"
                    />
                  </span>{" "}
                </h2>
                <p className="text-center">
                  If you are a Charity, CIC or Community Group – please indicate
                  this on your entry. We may ask further information to
                  substantiate your status. Those eligible will be able to
                  benefit from proceeds raised through bucket collection on the
                  day. 50% goes to Eastbourne Carnival towards next year’s
                  event, and the remaining 50% is shared with Charities and
                  those eligible.
                </p>
              </Col>
            </Row>
          }
          mainBg="bg-lightBlue"
          secondBg="bg-lightPurple"
          position="top-0"
          smallPos="top-0"
        />
      </section>
      <section
        style={{ overflow: "visible" }}
        className="pb-lg-10 pb-md-5 pt-md-5"
      >
        <Container>
          {" "}
          <PhotoSide
            image={g1Img}
            imageAlt={data.g1Img?.altText}
            order="last"
            heading={
              <h2 className=" fs-3 pb-4 pb-0 mb-0 shortstack text-secondary d-inline-block">
                Promoting your cause at Carnival
              </h2>
            }
            text={
              <span>
                <p>
                  Carnival is a celebration, and each entry{" "}
                  <span className="jost-bold">must</span> contain an element of
                  performance. This can be through music, singing, dancing or
                  costume presentation. Any banners should be to highlight your
                  organisation only i.e. your name, and not a statement of your
                  cause.
                </p>
                <p>
                  You can contact us by email if you have any queries about your
                  entry.
                </p>
              </span>
            }
          />
        </Container>
      </section>
      <section
        style={{ overflow: "visible" }}
        className="pb-md-10 pb-4  position-relative"
      >
        <StaticImage
          src="../images/festival.svg"
          style={{
            zIndex: -1,
            overflow: "visible",
            left: "-10%",
            maxWidth: "20%",
          }}
          className="position-absolute d-none d-md-block top--30  "
          quality={100}
          placeholder="Traced_Svg"
        />
        <Container>
          <Row>
            <Col>
              <h2 className="text-secondary carnival-normal text-center pb-5 fs-1">
                Environmental guidance for entrants and the public
              </h2>
              <h3 className="text-secondary shortstack fs-4 pb-4 ">Entrants</h3>
              <p>Eastbourne Carnival seeks to be as sustainable as possible.</p>
              <p>
                We work in line with Guidance from Eastbourne Borough Council
                and ask our entrants to comply with the following:
              </p>
              <p>
                1. Balloons are NOT permitted at Eastbourne Carnival. This is
                because they cause significant harm to Marine life.
              </p>
              <p>
                2. Single use plastic is prohibited (where relevant) i.e.
                plastic bags, drinks bottles, plastic cutlery, single use food
                containers and plastic cups.
              </p>
              <p>3. Entrants are discouraged from giving out stickers.</p>
              <p>
                4. Entrants are required to take responsibility of their own
                waste generated by their entry and take back and recycle.
              </p>
              <h3 className="text-secondary shortstack mt-5 fs-4 pb-4 ">
                The Public
              </h3>
              <p>
                1. Balloons are NOT permitted at Eastbourne Carnival. This is
                because they cause significant harm to Marine life.
              </p>
              <p>
                2. The public are encouraged to bring refillable bottles for
                water and use the Refill points along the route. Download the
                Refill App to locate the closest refill points.
              </p>
              <p>
                3. The public are advised to be responsible for their rubbish
                and ensure they place in appropriate bin or take home.
              </p>
              <p>
                4. Please consider how you get to Carnival – taking public
                transport, car sharing, walking or cycling as is practical if
                you can.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        {" "}
        <BottomBanner
          mainBg="bg-secondary"
          position="top--20"
          content={
            <Row>
              <Col className="py-5 py-md-0">
                <h2 className="text-white text-center mt-md-5 pt-md-10 fs-1 shortstack">
                  REGISTRATION: FOR ENTRANTS AND MARSHALS
                </h2>
                <div className="d-md-flex pt-md-5 pt-4 justify-content-md-center align-items-md-center">
                  {" "}
                  <Button
                    variant="outline-white"
                    className="fs-5 py-3 d-block d-md-inline-block mb-4 mb-md-0 w-100 w-md-auto px-4 me-5 jost-normal"
                    as={Link}
                    to="/entrant-form"
                  >
                    ENTRANTS REGISTRATION
                  </Button>
                  <Button
                    variant="outline-white"
                    className="fs-5 py-3 d-block d-md-inline-block w-100 w-md-auto  px-4 jost-normal"
                    as={Link}
                    to="/volunteer-form"
                  >
                    MARSHALLS REGISTRATION
                  </Button>
                </div>
              </Col>
            </Row>
          }
        />
      </section>
    </Layout>
  );
};

export default FurtherInfoPage;
