import React from "react";
import Container from "react-bootstrap/Container";

const TopLeftSection = ({ content, mainBg, secondBg, position, smallPos }) => {
	const positionSmall = smallPos || "top-20";
	return (
		<div className="position-relative angled-div">
			<div className="d-none d-md-block">
				<div
					style={{ zIndex: 1 }}
					className={`div-up-right-small ${secondBg} position-absolute w-100 h-100 ${positionSmall} `}
				></div>
				<div
					style={{ zIndex: 2 }}
					className={`div-up-left  ${mainBg} position-absolute w-100 h-100 ${position} `}
				>
					<Container>{content}</Container>
				</div>
			</div>
			<div
				style={{ zIndex: 2 }}
				className={`div-up-left  ${mainBg} d-md-none `}
			>
				<Container>{content}</Container>
			</div>
		</div>
	);
};

export default TopLeftSection;
